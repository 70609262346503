import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-affiliated-bodies',
  templateUrl: './affiliated-bodies.component.html',
  styleUrls: ['./affiliated-bodies.component.css']
})
export class AffiliatedBodiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0)
  }

}
