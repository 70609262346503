import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-governing-council',
  templateUrl: './governing-council.component.html',
  styleUrls: ['./governing-council.component.css']
})
export class GoverningCouncilComponent implements OnInit {

  filesScale1: any = [
    //President data
    {
      img: "assets/img/governing-council/pres.jpg",
      name: "Mr. Subrata Mukherjee",
      position: "Hon. President",
      //post: "Managing Director",
      //company: "Finance, CERAMIN ",
      contact: "971 55 4114235/971 55 219 3599",
      email: "president@ibpcrakuae.com/ssmk999@gmail.com"
    },
    //Secretary data
    {
      img: "assets/img/governing-council/abdul-rahim.jpg",
      name: "Mr. Abdul Rahim",
      position: "Hon. Secretary General",
      //post: "Deputy General Manager",
      //company: "Finance, CERAMIN ",
      contact: "971 50 799 0497",
      email: "secretarygeneral@ibpcrakuae.com/ abdulrahimrak@gmail.com"

    },
    //Treasurer data
    {
      img: "assets/img/governing-council/vsTreasurer.jpg",
      name: "Mr. Brijesh Harish Joshi",
      position: "Hon. Treasurer",
      //post: "Partner",
      //company: " Stuart & Hamlyn Chartered Accountant ",
      contact: "971 50 2778419",
      email: "treasurer@ibpcrakuae.com/ brijesh.j@gmail.com"
    },

    {
      img: "assets/img/governing-council/niraj.jpg",
      name: "Mr. Niraj Garg",
      position: "Hon. Vice President",
      // post: "MD",
      //company: "RAK Properties",
      contact: "971 50 6213528",
      email: "vice.president@ibpcrakuae.com/ngarg66@hotmail.com"
    },

    // {img:"assets/img/governing-council/vsSecretary.jpg"},
    {
      img: "assets/img/governing-council/vsSecretary.jpg",
      name: "Mr. Tansen Habib",
      position: "Hon. Jt Secretary General",
      //post: "Managing Director ",
      //company: " Al Sadaf Printing Press LLC",
      contact: " 055-5504323 / 07-2337760 ",
      email: "tansen.habib@gmail.com"
    },

    // {img:"assets/img/governing-council/vsTreasurer.jpg"},


    // {img:"assets/img/governing-council/member1.jpg"},

    {
      img: "assets/img/governing-council//member1.jpg",
      name: "Adv. Sunny Varughese ",
      position: "Hon. Member",
      //post: "CEO",
      //company: "Arab Business Centre",
      contact: "050 6702424/ 07-2046225",
      email: "abcenteruae@gmail.com"
    },

    // {img:"assets/img/governing-council/member2.jpg"},
    {
      img: "assets/img/governing-council/member2.jpg",
      name: "Mrs. Sakina I. Safdari",
      position: "Hon. Jt. Secretary General",
      // post:"Senior Manage",
      // company:"RAK",
      contact: "050-4956554",
      email: "jt.secretarygeneral@ibpcrakuae.com/sis@saftech.ae"
    },
    // {img:"assets/img/governing-council/member3.jpg"},
    // {
    // img:"assets/img/governing-council/member3.jpg",
    // name:"Mr. Saju Augustine",
    // position:"Hon. Member",
    // post:"Senior Manage",
    // company:"Morison Menon CA",
    // contact:"050-6317153",
    // email:" saju@morisonmenon.com"
    // },
    // {img:"assets/img/governing-council/member4.jpg"},
    {
      img: "assets/img/governing-council/ameish.jpg",
      name: "Mr. Ameish Kapur",
      position: "Hon. Jt. Treasurer",
      // post: "MD",
      // company: "Abusultan General Cleaning Services",
      contact: "971 58 6935221",
      email: "jt.treasurer@ibpcrakuae.com/ameish@gmail.com"
    },

    // {img:"assets/img/governing-council/member5.jpg"},
    {
      img: "assets/img/governing-council/member5.jpg",
      name: "Mr. JRC Babu",
      position: "Hon. Member",
      //post: "MD",
      //company: "Al Jazaa Electrical & Sanitary ",
      contact: "050-6476642/ 07-2227970",
      email: "groupofjazaa@gmail.com"
    },
    {
      img: "assets/img/governing-council/member6.jpg",
      name: "Shiv Shankar Kumar Sharma",
      position: "Hon. Member",
      // post:"MD",
      // company:"Al Jazaa Electrical & Sanitary ",
      contact: "050-3568071",
      email: "SHIVSHANKER09876@yahoo.co.in"
    },
    {
      img: "assets/img/governing-council/treasurer.jpg",
      name: "Mr. Ajith Abraham Jacob",
      position: "Hon. Member",
      // post:"MD",
      // company:"Al Jazaa Electrical & Sanitary ",
      contact: "971 55956 6861",
      email: "ajithjacob@stuaham.com"
    },
    {
      img: "assets/img/governing-council/president-prev.jpg",
      name: "Mr. Lokesh Verma",
      position: "Hon. Member",
      // post:"MD",
      // company:"Al Jazaa Electrical & Sanitary ",
      contact: "971 559501172/050 3743314",
      email: "Lkverma@powernsun.com"
    }
  ];

  constructor() { }

  ngOnInit() {
    console.log(this.filesScale1);
    window.scrollTo(0, 0);
  }

}
