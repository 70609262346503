import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fat-footer',
  templateUrl: './fat-footer.component.html',
  styleUrls: ['./fat-footer.component.css']
})
export class FatFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
